<template>
  <div id="app">
    <a-config-provider :locale="locale">
    <router-view v-if="isInited" />
    </a-config-provider>
  </div>
</template>
<script>
import UserApi from '@/api/systemSetting';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
  name: 'App',
  methods: {
    ...mapActions({
      getMenuInfo: 'getMenuInfo',
    }),
    ...mapMutations({
      updateUserInfo: 'UPDATE_USERINFO',
    }),
  },
  props: {
    accessed: Boolean,
  },
  data () {
    return {
      isInited: false,
      locale: zhCN,
    };
  },
  computed: {
    ...mapGetters([
      'menuTree',
    ]),
  },
  async created () {
    let userInfo = this.$local.get('userInfo') ? JSON.parse(this.$local.get('userInfo')) : null;
    if (userInfo) {
      // token过期需重新跳转至登陆页
      let userDetail = await UserApi.getDetailEmploy({id: userInfo.id, findDepartmentFlag: true, findRoleFlag: true}).catch(()=>{
        return {
          heads: {
            code: -1,
          },
        };
      });
      if (userDetail.heads.code === -1) {
        this.isInited = true;
        return;
      }
      this.$local.set('userInfo', userDetail.body);
      this.updateUserInfo(userDetail.body);
      await this.getMenuInfo();
      this.isInited = true;
      if ((this.$route && ['HOME', '/'].includes(this.$route.name)) && !this.accessed) {
        this.$router.replace(this.$store.getters.firstUrl);
      }
    } else {
      this.isInited = true;
    }
  },
};
</script>
<style>
html,body {
  width: 100%;
  height: 100%;
  min-width: 1366px;
  box-sizing: border-box;
}

* {
  padding: 0;
  margin: 0;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  /* font-family: PingFangSC-Medium, PingFang SC; */
  /* font-family: "Arial","Microsoft YaHei","黑体","宋体",sans-serif; */
  font-family: Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif,'PingFangSC-Medium', 'PingFang SC';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
