/*
 * @Date: 2021-09-27 16:34:55
 * @Author: Jeason
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-08-12 17:44:52
 * @Description: 系统管理
 * @FilePath: \maintenancef:\sass_web\maintenance_expert\src\api\systemSetting.js
 */
import server from '@/request';
export default {
  editUserInfo (params) {
    return server.post('/xiaozi-xmo/wireless/saas/tenant/editSaasInfo', params);
  },
  getSassUserInfo (params) {
    return server.post('/xiaozi-xmo/wireless/saas/tenant/querySaasInfo', params);
  },
  // 账套列表分页
  getAccountLists (params) {
    return server.post('/xiaozi-xmo/wireless/saas/ledger/queryPage', params);
  },
  // 账套详情
  getDetailAccount (params) {
    return server.post('/xiaozi-xmo/wireless/saas/ledger/details', params);
  },
  // 绘制部门树
  getDepartmentLists (params) {
    return server.post('/xiaozi-xmo/wireless/saas/department/departmentBuild', params);
  },
  // 部门详情
  getDetailDepartment (params) {
    return server.post('/xiaozi-xmo/wireless/saas/department/details', params);
  },
  // 新增部门
  addNewDepartment (params) {
    return server.post('/xiaozi-xmo/wireless/saas/department/add', params);
  },
  // 删除部门
  deleteDepartment (params) {
    return server.post('/xiaozi-xmo/wireless/saas/department/delete', params);
  },
  // 修改部门
  updateDepartment (params) {
    return server.post('/xiaozi-xmo/wireless/saas/department/edit', params);
  },
  //  批量移动员工
  batchAdjustDepartment (params) {
    return server.post('/xiaozi-xmo/wireless/saas/department/batchMove', params);
  },
  // 员工详情(根据员工ID)
  detailEmploy (params) {
    return server.post('/xiaozi-xmo/wireless/saas/department/employDetails', params);
  },
  // 员工分页列表
  getEmployLists (params) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/page', params);
  },
  // 员工详情
  getDetailEmploy (params) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/detail', params);
  },
  // 删除员工
  deleteEmployee (params) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/delete', params);
  },
  // 新增员工
  addEmployee (params) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/add', params);
  },
  // 更新员工
  updateEmployee (params) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/update', params);
  },
  // 更新员工基本信息
  employeeUpdate (params) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/update/employee', params);
  },
  // 更新账号状态
  updateAccountStatus (params) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/updateAccountStatus', params);
  },
  // 更新姓名
  updateAccountName (params) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/updateName', params);
  },
  // 更新密码
  updateAccountPassword (params) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/updatePassword', params);
  },
  // 重置密码
  resetPassword (params) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/resetPassword', params);
  },
  //  角色分页列表
  getRoleLists (params) {
    return server.post('/xiaozi-xmo/wireless/saas/role/page', params);
  },
  //  角色详情
  getDetailRole (params) {
    return server.post('/xiaozi-xmo/wireless/saas/role/detail', params);
  },
  //  新增角色
  addRole (params) {
    return server.post('/xiaozi-xmo/wireless/saas/role/add', params);
  },
  //  更新角色
  updateRole (params) {
    return server.post('/xiaozi-xmo/wireless/saas/role/update', params);
  },
  //  删除角色
  deleteRole (params) {
    return server.post('/xiaozi-xmo/wireless/saas/role/delete', params);
  },
  // 校验角色是否关联员工
  checkRelEmployeeFlag (params) {
    return server.post('/xiaozi-xmo/wireless/saas/role/checkRelEmployeeFlag', params);
  },
  //  产品菜单树
  getProductMenuTree (params) {
    return server.post('/xiaozi-xmo/wireless/saas/menu/productMenuTree', params);
  },
  // 员工标签分页列表
  getlabels (params) {
    return server.post('/xiaozi-xmo/wireless/saas/label/page', params);
  },
  // 新增员工标签
  addLabel (params) {
    return server.post('/xiaozi-xmo/wireless/saas/label/add', params);
  },
  // 删除员工标签
  deleteLabel (params) {
    return server.post('/xiaozi-xmo/wireless/saas/label/delete', params);
  },
  // 编辑员工标签
  updateLabel (params) {
    return server.post('/xiaozi-xmo/wireless/saas/label/update', params);
  },
  // 休假处理
  dealVacation (params) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/vacation', params);
  },
  // 获取业务场景
  getBusScenario (params) {
    return server.post('/xiaozi-xmb/wireless/saas/customize/busScenario/listById', params);
  },
};

// 企业认证提交
export const authenticationTenant = (params) =>{
  return server.post('/xiaozi-xmo/wireless/miniapp/tenant/authentication', params);
};
// 企业详情
export const getDetailTenantInfo = (params) =>{
  return server.post('/xiaozi-xmo/wireless/miniapp/tenant/detail', params);
};

// 生成审核表PDF文件
export const generateAuditFile = (params) =>{
  return server.post('/xiaozi-xmo/wireless/miniapp/tenant/generateAuditFile', params, {
    responseType: 'blob',
  });
};

// 企业行业能力（新增，编辑，删除）
export const getIndustryCapacity = (params) =>{
  return server.post('/xiaozi-xmo/wireless/miniapp/tenant/industryCapacity', params);
};

// 企业业务能力详情列表
export const getDetailIndustryCapacity = (params) =>{
  return server.post('/xiaozi-xmo/wireless/miniapp/tenant/industryCapacity/detail', params);
};

// 企业基本信息
export const getDetailTenantBaseInfo = (params) =>{
  return server.post('/xiaozi-xmo/wireless/miniapp/tenant/information/baseinfo', params);
};

// 企业入驻校验是否是小紫供应商(返回值name有值代表已经是小紫合作供应商)
export const checkTenantInformation = (params) =>{
  return server.post('/xiaozi-xmo/wireless/miniapp/tenant/information/check', params);
};

// 企业材料或者入驻材料详情
export const getDetailTenantInformationInfo = (params) =>{
  return server.post('/xiaozi-xmo/wireless/miniapp/tenant/information/detail', params);
};

// 企业入驻分享-提交审核操作数据必填性校验
export const requiredVerification = (params) =>{
  return server.post('/xiaozi-xmo/wireless/miniapp/tenant/information/requiredVerification', params);
};

// 企业材料或者入驻材料新增-编辑
export const updateTenantInformation = (params) =>{
  return server.post('/xiaozi-xmo/wireless/miniapp/tenant/information/update', params);
};

// 企业更新（完善企业介绍，公司规模，经营类型,企业logo，企业名称五个信息
export const updateTenantInfo = (params) =>{
  return server.post('/xiaozi-xmo/wireless/miniapp/tenant/update', params);
};

// 设备列表
export const getBaseSparePartDeviceLists = (params) =>{
  return server.post('/xiaozi-xmb/wireless/saas/sparePart/base/device', params);
};
